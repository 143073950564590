html {
  scroll-behavior: smooth;
}

@import url("https://use.typekit.net/yri4mff.css");

:root {
  --white: #F8F8F5;
  --neutral: #E8E8E5;
  --black: #000;
  --green: #277d68;
  --purple: #cbc9e4;
  --red: #d12128;
  --brat: #8ACE00;
  --blue: #132046;
  --headerFont: "kepler-std-display", serif;
  --bodyFont: "futura-pt", sans-serif;
  --fancyFont: "nautica", sans-serif;
  --fancyFont2: "la-bohemienne", sans-serif;
}

::selection {
  background: var(--purple);
  color: var(--black);
}

.mobile {
  display: none !important;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}

/*=====================================
=======================================
          Web accessibility
=======================================
======================================= */
a.skip-main {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
}

a.skip-main:focus,
a.skip-main:active {
  color: var(--white);
  background-color: var(--green);
  left: 5px;
  top: 5px;
  width: 150px;
  height: auto;
  overflow: auto;
  padding: 5px 5px 7px 5px;
  text-align: center;
  z-index: 999;
  text-decoration: none;
}

/*=====================================
=======================================
              Universal
=======================================
======================================= */

body {
  background-color: var(--white);
  color: black;
  font-family: var(--bodyFont);
  font-size: 20px;
}

main {
  background-color: var(--white);
  padding-top: 110px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  main {
    margin-top: 90px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  main {
    padding-top: 80px;
  }
}

.wrapper {
  width: 90%;
  max-width: 1800px;
  margin: 0 auto;
}

.atf {
  padding: 120px 0;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .atf {
    padding: 0 0 100px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .atf {
    padding: 0 0 80px 0;
  }
}

.atf {
  position: relative;
  margin-bottom: 0;
  margin: initial;
}

.atf::after {
  content: "";
  width: 80%;
  height: .5px;
  background: var(--black);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.atf h1 {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headerFont);
  font-weight: 300;
  font-style: normal;
  letter-spacing: -.02em;
  animation: fade-in 1s;
  line-height: 1;
}

p {
  animation: fade-in 31;
  font-size: 18px;
}

img {
  animation: fade-in 1s;
  z-index: 1;
  width: 100%;
  height: auto;
}

.h-italics {
  font-style: italic;
  font-weight: 300;
}

h1 {
  text-align: center;
  font-size: 105px;
}

h2 {
  font-size: 55px;
}

h3 {
  font-size: 58px;
}

h4 {
  font-size: 46px;
}

h5 {
  font-size: 37px;
}

h6 {
  font-size: 30px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  h1 {
    text-align: center;
    font-size: 60px;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 46px;
  }

  h4 {
    font-size: 37px;
  }

  h5 {
    font-size: 30px;
  }

  h6 {
    font-size: 26px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  h1 {
    text-align: center;
    font-size: 43px;
  }

  h2 {
    font-size: 35px;
  }

  h3 {
    font-size: 33px;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 26px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}



@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}


.error-page {
  min-height: 63vh;
}

.error-page .error-wrap {
  text-align: center;
}

.error-page .error-wrap h1 {
  margin-top: 160px;
  margin-bottom: 0;
}