.about-wrapper {
  overflow: hidden;
}

.about-atf {
  padding: 100px 0;
}

.about-atf .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-atf {
    padding: 10px 0 50px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-wrapper {
    padding-top: 0;
  }

  .about-atf {
    padding: 30px 0;
  }

  .about-atf .wrapper {
    justify-content: flex-end;
  }
}

.about-atf .left {
  width: 30%;
  margin: 0 2%;
  position: relative;
}

.about-atf .right {
  width: 35%;
  margin: 0 2%;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-atf .right {
    width: 90%;
    margin: 0 2%;
  }

  .about-atf .left {
    width: 60%;
    margin: 0 2%;
    position: relative;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-atf .right {
    width: 95%;
    margin: 0 2%;
  }

  .about-atf .left {
    width: 80%;
    margin: 0 2%;
    position: relative;
  }
}

.about-atf .left h2 {
  position: absolute;
  top: 15%;
  left: -50%;
  transform: rotate(-90deg);
  font-family: var(--fancyFont);
  font-size: 200px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-atf .left h2 {
    margin: 0;
    top: 44%;
    left: -48%;
    font-size: 222px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-atf .left h2 {
    margin: 0;
    top: 40%;
    left: -60%;
    font-size: 161px;
  }
}

/* =====================
========================
    Right
========================
===================== */

.about-atf h1 {
  font-size: 55px;
  max-width: 1200px;
  margin: 30px auto;
  line-height: .9;
  text-align: left;
  text-transform: lowercase;
  font-style: italic;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-atf h1 {
    font-size: 66px;
    width: 100%;
  }

  .about-atf h1 br {
    display: none;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-atf h1 {
    font-size: 33px;
  }
}

/*=======================
=========================
       About Intro
=========================
========================= */
.about-intro {
  width: 100%;
  position: relative;
  padding: 70px 0 120px 0;
}

.about-intro img {
  width: 20%;
  height: auto;
  position: absolute;
}

.about-intro .image-1 {
  bottom: -100px;
  left: -10px;
}

.about-intro .image-2 {
  top: 45%;
  transform: translateY(-50%);
  right: -10px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {

  .about-intro .image-1,
  .about-intro .image-2 {
    display: none;
  }

  .about-intro {
    padding: 50px 0;
  }

}

.about-intro .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-intro h2 {
  max-width: 700px;
  margin: 10px 0;
}

.about-intro .sub-title {
  text-transform: uppercase;
  font-size: 14px;
}

.about-intro a {
  text-decoration: none;
  color: var(--black);
  position: relative;
  text-transform: uppercase;
  margin-top: 40px !important;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
}

.about-intro a::after {
  content: "";
  width: 110%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -5%;
  opacity: 0;
}

.about-intro a:hover:after {
  opacity: 1;
}

/*=======================
=========================
       About Match
=========================
========================= */

.about-match {
  padding: 100px 0;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-match {
    padding: 50px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-match {
    padding: 0 0 50px 0;
  }
}

.about-match .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about-match .right {
  width: 30%;
  margin: 0 1%;
}

.about-match .left {
  width: 27%;
  margin: 0 1%;
}

.about-match .image-2 {
  position: absolute;
  width: 250px;
  left: -6%;
  bottom: -200px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-match .right {
    width: 40%;
    margin: 0 1%;
    margin-right: initial;
  }

  .about-match .left {
    width: 50%;
    margin: 0 1%;
    margin-right: intial;
  }

  .about-match .image-2 {
    display: none;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-match .wrapper {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .about-match .right {
    width: 80%;
    margin: 0;
  }

  .about-match .left {
    width: 100%;
    margin: 0;
  }
}

.about-match h3 {
  text-align: left;
  margin: 20px 0;
  margin-left: -10px;
  font-size: 48px;
}

.about-match ol {
  padding: 0;
  margin: 0;
}

.about-match ol li {
  margin: 10px 0;
  font-size: 22px;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-match h3 {
    font-size: 33px;
    margin-left: 10px;
  }

  .about-match ol {
    padding: 0 20px;
  }

  .about-match ol li {
    margin: 10px;
    font-size: 20px;
  }
}



/*=======================
=========================
       About Mission
=========================
========================= */

.about-mission {
  background-color: var(--black);
  color: var(--white);
  padding: 120px 0 100px 0;
  position: relative;
}

.about-mission .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.about-mission h2 {
  max-width: 710px;
  margin: 10px 0;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-mission h2 {
    max-width: 400 !important;
    font-size: 53px;
    margin: 10px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-mission {

    padding: 70px 0;
  }

  .about-mission h2 {
    max-width: 400 !important;
    font-size: 38px;
    margin: 10px 0;
  }
}

.about-mission .sub-title {
  text-transform: uppercase;
  font-size: 14px;
}

.about-mission a {
  text-decoration: none;
  color: var(--white);
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.about-mission a::after {
  content: "";
  width: 110%;
  height: 2px;
  background: var(--white);
  position: absolute;
  top: 50%;
  left: -5%;
  opacity: 0;
}

.about-mission a:hover:after {
  opacity: 1;
}

/*=======================
=========================
       About Janel
=========================
========================= */
.about-janel {
  padding: 100px 0;
}

.about-janel .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-janel {
    padding: 80px 0;
  }
}


.about-janel .left {
  width: 30%;
  margin: 0 2%;
}

.about-janel .right {
  width: 40%;
  margin: 0 2%;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-janel .left {
    width: 40%;
    margin: 0 2%;
  }

  .about-janel .right {
    width: 50%;
    margin: 0 2%;
  }

  .about-janel .mobile,
  .about-janel .mobile h2 {
    display: block !important;
    width: 100%;
    text-align: center;
  }

  .about-janel .mobile h2 {
    font-size: 55px;
  }

  .about-janel .desktop {
    display: none;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-janel .sub-title {
    font-size: 16px;
  }

  .about-janel .mobile h2 {
    font-size: 33px;
  }

  .about-janel .left {
    width: 80%;
    margin: 0;
  }

  .about-janel .right {
    width: 100%;
    margin: 0;
  }
}

.about-janel .sub-title {
  text-transform: uppercase;
  margin: 0;
}

.about-janel h2 {
  margin: 10px auto 30px auto;
  line-height: 1;
  text-align: left;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-janel h1 {
    font-size: 48px;
    width: 90%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-janel h1 {
    font-size: 33px;
  }
}

.about-janel .cta {
  padding: 50px 0;
  text-align: center;
}

.about-janel .cta a {
  text-decoration: none;
  color: var(--black);
  position: relative;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-janel .cta {
    padding: 10px 0;
    text-align: center;
    font-style: italic;
  }

  .about-janel .cta a {
    font-style: normal;
  }
}

.about-janel .cta a::after {
  content: "";
  width: 110%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -5%;
  opacity: 0;
}

.about-janel .cta a:hover:after {
  opacity: 1;
}

/*=======================
=========================
       About Millie
=========================
========================= */
.about-millie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--neutral);
}

.about-millie .left {
  width: 50%;
  margin: 0;
}

.about-millie .right {
  width: 48%;
  text-align: center;
  padding: 40px 0;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-millie .left {
    display: none;
  }

  .about-millie .right {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
}

.about-millie .sub-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
}

.about-millie .millie-title {
  font-size: 44px;
  margin: 10px 0;
}

.about-millie .millie-image-text {
  position: relative;
}

.about-millie .millie-image-text img {
  width: 40%;
}

.about-millie .millie-image-text h2 {
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translate(-50%);
  font-family: var(--bodyFont);
  text-transform: uppercase;
  font-size: 200px;
  font-weight: 600;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .about-millie .millie-image-text img {
    width: 40%;
  }

  .about-millie .millie-image-text h2 {
    top: 20%;
    font-size: 80px;
  }

}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .about-millie .millie-image-text img {
    width: 50%;
  }

  .about-millie .millie-image-text h2 {
    top: 10%;
    font-size: 111px;
  }

}