.home-page {
  overflow: hidden;
}

/*=====================================
=======================================
          Home ATF
=======================================
======================================= */

.home-atf:after {
  opacity: 0 !important;
}

.rfm-marquee .rfm-child {
  font-size: 22px;
  margin: 0 20px;
  text-transform: uppercase;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .rfm-marquee .rfm-child {
    font-size: 20px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .rfm-marquee .rfm-child {
    font-size: 17px;
  }
}


/*=====================================
=======================================
          Recent Work
=======================================
======================================= */

.recent-work {
  padding: 90px 0;
}

.recent-work h2,
.recent-work .see-all {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 5px 0;
}

.recent-work .see-all {
  font-family: var(--headerFont);
  font-style: italic;
  text-align: right;
  font-size: 30px;
  text-transform: lowercase;
  color: var(--black);
}

.recent-work .projects {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
}

.recent-work .projects .each-project {
  width: 23%;
  margin: 1%;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .recent-work {
    padding: 70px 0;
  }

  .recent-work .projects .each-project {
    width: 47.5%;
  }

  .recent-work .projects .each-project:first-child {
    order: 2;
  }

  .recent-work .projects .each-project:nth-child(2) {
    order: 1;
  }

  .recent-work .projects .each-project:nth-child(3) {
    order: 3;
  }

  .recent-work .projects .each-project:last-child {
    order: 4;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .recent-work H2 {
    font-size: 30px;
  }

  .recent-work .see-all {
    font-size: 22px;
  }
}

/*=====================================
=======================================
          Meet Janel
=======================================
======================================= */

.meet-janel {
  padding: 60px 0 160px 0;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .meet-janel {
    padding: 50px 0 120px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .meet-janel {
    padding: 50px 0 100px 0;
  }
}

.meet-janel .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.meet-janel .left {
  width: 30%;
  margin: 0 2%;
  position: relative;
}

.meet-janel .right {
  width: 45%;
  max-width: 400px;
  margin: 0 2%;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .meet-janel .left {
    width: 40%;
  }

  .meet-janel .right {
    width: 50%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .meet-janel .left {
    width: 80%;
  }

  .meet-janel .right {
    width: 100%;
    margin-top: 50px;
  }
}

.meet-janel .left .home-about-footer-text {
  position: absolute;
  bottom: -30px;
  left: -50px;
  height: 200px;
  width: 200px;
}

.meet-janel h2 {
  margin-bottom: 10px;
  font-size: 50px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .meet-janel .left .home-about-footer-text {
    left: -25px;
    height: 150px;
    width: 150px;
    height: 150px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .meet-janel .left .home-about-footer-text {
    bottom: -30px;
    left: -30px;
  }

  .meet-janel h2 {
    font-size: 33px;
  }
}

.meet-janel .sub-title {
  text-transform: uppercase;
  margin: 0;
}

.meet-janel strong {
  font-family: var(--bodyFont);
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
}

.meet-janel .cta {
  text-decoration: none;
  color: var(--black);
  position: relative;
  text-transform: uppercase;
  margin-top: 40px !important;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 600;
}

.meet-janel .cta::after {
  content: "";
  width: 110%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -5%;
  opacity: 0;
}

.meet-janel .cta:hover:after {
  opacity: 1;
}


/*=====================================
=======================================
          Services
=======================================
======================================= */

.home-services {
  min-height: 250px !important;
  padding: 120px 0 60px 0 !important;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .home-services {
    padding: 120px 0 !important;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .home-services {
    padding: 100px 0 !important;
  }
}

.home-services .home-service-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
}

.home-services .home-service-wrap .each-service {
  width: 22%;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .home-services .home-service-wrap .each-service {
    width: 45%;
    margin: 2% 1%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .home-services .home-service-wrap .each-service {
    width: 90%;
    margin: 6% auto;
  }
}

.home-services .home-service-wrap .each-service h3 {
  font-family: var(--bodyFont);
  font-weight: 900;
  letter-spacing: 2px;
  font-size: 20px;
  text-transform: uppercase;
}

.home-services .home-service-wrap .each-service p {
  font-size: 18px;
  margin-bottom: 0;
}

/*=====================================
=======================================
          The Process
=======================================
======================================= */

.home-process {
  padding: 120px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .home-process {
    padding: 70px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .home-process {
    display: none;
  }
}

.home-process .left {
  width: 95%;
  max-width: 650px;
  margin: 0 2%;
}

.home-process h2 {
  font-family: var(--bodyFont);
  font-weight: 900;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 40px;
}


.home-process .each-step {
  padding: 10px 0;
  margin: 20px 0;
  border-bottom: 1px solid var(--black);
}

.home-process .each-step:last-child {
  border-bottom: none;
}

.home-process .each-step h3 {
  font-family: var(--bodyFont);
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

.home-process .each-step p {
  margin-top: 5px;
}


.home-process .cta {
  padding: 50px 0;
  text-align: center;
}


.home-process .image-2,
.about-mission .image-2 {
  width: 20%;
  height: auto;
  position: absolute;
  bottom: -15%;
  right: -10px;
}

.about-mission .image-2 {
  bottom: -30%;
  transform: translateY(-25%);
  right: initial;
  left: -10px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {

  .home-process .image-2,
  .about-mission .image-2 {
    display: none;
  }
}

/*=====================================
=======================================
          Testimonials
=======================================
======================================= */
.testimonials {
  margin-bottom: 120px;
}

.testimonials h2 {
  margin: 0;
  margin-bottom: -40px;
  text-align: center;
}

.carousel-root {
  width: 80% !important;
  margin: auto !important;
}

.carousel .slide {
  height: 100%;
}

.myCarousel {
  width: 90%;
  max-width: 888px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 350px;
}

.myCarousel h3 {
  letter-spacing: 0.2px;
  margin: 40px 0 20px 0;
  font-family: var(--bodyFont);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
}

@media only screen and (max-width: 934px) {
  .myCarousel {
    width: 100% !important;
    height: 329px !important;
  }
}

@media only screen and (max-width: 650px) {
  .myCarousel {
    height: 449px !important;
  }
}

.carousel.carousel-slider .control-arrow {
  color: var(--black);
  height: 50px !important;
  position: absolute;
  top: initial;
  bottom: -2% !important;
  opacity: 1;
  font-size: 30px;
  z-index: 1;
}

.carousel.carousel-slider .control-next {
  right: 43%;
}

.carousel.carousel-slider .control-prev {
  left: 43%;
}

.carousel .control-next.control-arrow:before {
  content: "→" !important;
  border-left: none !important;
  font-family: sans-serif;
}

.carousel .control-prev.control-arrow:before {
  content: "←" !important;
  border-right: none !important;
  font-family: var(--bodyFont);
}

.carousel.carousel-slider .control-arrow:hover {
  background: initial;
}

.App {
  text-align: center;
}


@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }

  .myCarousel {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    height: 269px;
  }
}

/*=====================================
=======================================
          Client CTA
=======================================
======================================= */
.divider {
  padding: 0;
  text-align: center;
  position: relative;
  width: 100%;
  display: block;
  height: 2px;
}

.divider::after {
  content: "";
  width: 80%;
  height: .5px;
  background: var(--black);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.client-call-to-action {
  padding: 120px 0;
  text-align: center;
  position: relative;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .client-call-to-action {
    padding: 90px 0;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .client-call-to-action {
    padding: 70px 0;
  }
}

.client-call-to-action::after {
  content: "";
  width: 80%;
  height: .5px;
  background: var(--black);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.client-call-to-action-2::after {
  opacity: 0 !important;
  background: transparent;
}

.client-call-to-action h2 {
  margin: 0;
}

.client-call-to-action a {
  text-decoration: none;
  color: var(--black);
  position: relative;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

.client-call-to-action a::after {
  content: "";
  width: 110%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -5%;
  opacity: 0;
}

.client-call-to-action a:hover:after {
  opacity: 1;
}