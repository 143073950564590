.shop-page {
  margin-top: 0;
  padding-top: 110px;
  z-index: 2;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .shop-page {
    padding-top: 88px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-page {
    padding-top: 80px;
  }
}

.shop-page a {
  color: var(--black);
}

/*=====================================
=======================================
          Each Shop Item ATF
=======================================
======================================= */
.shop-atf {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.shop-atf .left {
  width: 50%;
  overflow: hidden;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-atf .left {
    width: 100%;
  }
}

.shop-atf .left .slide-container {
  width: 100%;
}

.shop-atf .left.slide-container .image-container {
  width: 100%;
  height: 100%;
}

.shop-atf .left .slide-container .image-container img {
  width: 100%;
  height: 100%;
}

.shop-atf .right {
  width: 40%;
  padding: 40px;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .shop-atf .right {
    width: 40%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-atf .right {
    width: 100%;
    padding: 20px;
    margin-bottom: 50px;
  }
}

.shop-atf .right h1 {
  text-align: left;
  margin: 0;
}

.shop-atf .right p {
  max-width: 600px;
}

.shop-atf .right a {
  margin-top: 15px;
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.shop-atf .right a::after {
  content: "";
  width: 102%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -1%;
  opacity: 0;
}

.shop-atf .right a:hover:after {
  opacity: 1;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .shop-atf .right h1 {
    font-size: 60px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-atf .right h1 {
    font-size: 50px;
  }

  .shop-atf .right a {
    font-size: 14px;
  }
}

/*=======================
=========================
      Each Shop Item ATF ALT
=========================
========================= */

.shop-atf-alt {
  align-items: flex-start;
  position: relative;
  height: 87vh;
  overflow: scroll;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-atf-alt {
    height: initial;
  }
}

.shop-atf-alt .right {
  position: sticky;
  top: 120px;
  right: 0;
  z-index: 1;
}

/*=====================================
=======================================
          Section 2: Intro
=======================================
======================================= */

.shop-item-intro {
  background-color: var(--neutral);
  z-index: 2;
  margin: 0;
  padding: 120px 20px;
  text-align: center;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .shop-item-intro {
    padding: 90px 20px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-item-intro {
    padding: 70px 20px;
  }
}

.shop-item-intro h2 {
  margin: 20px auto;
}

.shop-item-intro .sub-title {
  text-transform: uppercase;
  margin-bottom: 30px;
}

.shop-item-intro p {
  max-width: 744px;
  margin: 10px auto;
}

/*=====================================
=======================================
          Section 3: Paralax
=======================================
======================================= */

.paralax {
  min-height: 250px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 120px 0;
  text-align: center;
  z-index: 2;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .paralax {
    padding: 90px 20px;
    min-height: 200px;
    background-attachment: scroll !important;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .paralax {
    padding: 70px 20px;
  }
}

.paralax h2 {
  margin: 20px auto;
  font-weight: 400;
}

.paralax h2 .h-italics {
  font-weight: 500;
}

.paralax p {
  max-width: 650px;
  margin: 10px auto;
}

/*=====================================
=======================================
          Each Shop Images
=======================================
======================================= */

.shop-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 70px auto;
  position: relative;
}

.floating-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transform: rotate(-90deg);
  right: -45px;
  background-color: var(--black);
  color: var(--white) !important;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  z-index: 2;
}

.floating-button:hover {
  filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, 0.5));
}

.shop-images img {
  width: 46%;
  margin: 2%;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .shop-images img {
    width: 46%;
    margin: 10px auto;
  }
}

.shop-images .section-text,
.intro .section-text {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.shop-images .section-text h2,
.intro .section-text h2 {
  width: 100%;
  max-width: 230px;
  margin: 20px 10px;
  font-size: 44px;
  text-align: center;
}

.shop-images .section-text p,
.intro .section-text p {
  width: 100%;
  max-width: 300px;
  font-size: 15px;
  margin: 20px;
  text-align: justify;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {

  .shop-images .section-text h2,
  .intro .section-text h2 {
    max-width: 22 0px;
  }

  .shop-images .section-text p,
  .intro .section-text p {
    max-width: 230px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {

  .shop-images .section-text h2,
  .intro .section-text h2 {
    max-width: 80%;
  }

  .shop-images .section-text p,
  .intro .section-text p {
    max-width: 90%;
  }
}

.shop-images .section-text-2 {
  width: 100%;
}

.shop-images .section-text-2 p {
  text-align: center;
  font-size: 18px;
  width: 70%;
  max-width: 500px;
  margin: 30px auto;
}

.mobile {
  display: none;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

/*=====================================
=======================================
          EShop CTA
=======================================
======================================= */


.shop-page .client-call-to-action {
  background-color: var(--neutral);
}

.shop-page .client-call-to-action::after {
  opacity: 0 !important;
}

/*=====================================
=======================================
          Recommended Products
=======================================
======================================= */


.shop-single .recommended-products h2 {
  text-transform: lowercase;
  font-style: italic;
}

.shop-single .recommended-products p {
  max-width: 650px;
}

.shop-single .recommended-products .back-top-shop {
  display: flex;
}

.shop-single .recommended-products .back-top-shop a {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  margin: 30px auto 0 auto;
}

.shop-single .recommended-products .back-top-shop a::after {
  content: "";
  width: 102%;
  height: 2px;
  background: var(--black);
  position: absolute;
  top: 50%;
  left: -1%;
  opacity: 0;
}

.shop-single .recommended-products .back-top-shop a:hover:after {
  opacity: 1;
}

.shop-single .brand-kit-wrapper {
  width: 80%;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 25px;
}

.shop-single .brand-kit-wrapper::after {
  opacity: 0;
}

.shop-single .each-shop-item h3 {
  font-size: 33px !important;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {

  .shop-single .brand-kit-wrapper .each-shop-item:last-child {
    display: none;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .intro .brand-kit-wrapper .each-shop-item {
    width: 47%;
    margin: 1.5% auto;
  }

  .shop-single .each-shop-item h3 {
    font-size: 24px !important;
  }

}