.service-page {
  margin-top: 0;
}

.service-page .about-mission,
.testimonials {
  padding: 70px 0;
  margin: 0 !important;

}

.testimonials {
  background-color: var(--neutral);
}

.atf p {
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.service-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 70px 0;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.service-wrap .service {
  width: 47%;
  max-width: 650px !important;
  margin: 0 auto;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  .service-page {
    margin-top: 60px;
  }

  .service-wrap {
    width: 95%;
  }

  .service-wrap .service {
    width: 45%;

  }

  .service-wrap .service {
    width: 100%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .service-page {
    margin-top: 80px;
  }

  .service-wrap .service {
    width: 90%;
    margin: 0 auto;
  }
}

.service-page .client-call-to-action::after {
  display: none;
}

.service-wrap .service h2 {
  margin-bottom: 5px;
  font-family: var(--bodyFont) !important;
  margin: 40px 0 0 0;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
}

.service-wrap .service p {
  margin-top: 15px;
}