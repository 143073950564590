.Footer {
  width: 100%;
  background-color: var(--neutral);
}

.f-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  .f-wrapper {
    flex-direction: column;
    padding-top: 10px;
  }
}

footer a {
  text-decoration: none;
  color: var(--black);
  position: relative;
  font-size: 18px;
  display: inline-block;
}

footer a::after {
  content: "";
  width: 102%;
  height: 1.5px;
  background: var(--black);
  position: absolute;
  top: 45%;
  left: -.5%;
  opacity: 0;
}

footer a:hover:after {
  opacity: 1;
}

footer .footer-center {
  width: 40%;
  margin: 20px 40px;
  text-align: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  footer .footer-center {
    width: 50%;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  footer .footer-center {
    width: 100%;
    margin: 40px 0 0 0;
    border-left: none;
    border-right: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer .footer-center .nav-links {
    width: initial;
    margin-top: 20px;
    display: inline-block;
    text-align: center;
  }

  footer .footer-center .nav-links a {
    display: block;
    font-size: 16px;
    margin: 10px 0;
  }
}

footer .footer-center .logo {
  text-decoration: none;
  color: var(--black);
  text-transform: uppercase;
  font-weight: 900;
  font-size: 22px;
  text-align: center;
  margin: 0 !important;
  z-index: 1;
}

footer .footer-center .logo::after {
  opacity: 0;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {

  footer .footer-center .logo {
    font-size: 18px;
  }
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {

  footer .footer-center .logo {
    font-size: 14px;
    margin-left: 35px;
  }
}

footer .footer-center .socials {
  margin-top: 10px;
}

footer .footer-center .socials .each-social {
  margin: 0 5px;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}

footer .nav-links {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  width: 20%;
}

@media only screen and (pointer: coarse) and (max-width: 1025px),
screen and (max-width: 1025px) {
  footer .nav-links {
    width: 10%;
  }
}

footer .nav-links a {
  margin: 5px 0;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}

footer .copyright {
  width: 100%;
  font-size: 13px;
  text-align: center;
  margin: 50px 0 30px 0;
}

@media only screen and (pointer: coarse) and (max-width: 650px),
screen and (max-width: 650px) {
  footer .copyright {
    margin: 25px 0 40px 0;
  }
}


.footer-icon-logo {
  width: 50%;
  max-width: 150px;
  padding-top: 70px;
}

.footer-icon-logo a:after {
  display: none !important;
}